import React from "react"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import work_reprise from "../images/work-banner-reprise.jpg"
import work_catalant from "../images/work-banner-catalant.jpg"
import work_insightsquared from "../images/work-banner-insightsquared.jpg"
import work_levelup from "../images/work-banner-levelup.jpg"

const duration = 0.7

const container = {
  visible: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.02,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const WorkPage = () => {
  return (
    <>
      <SEO title="Work" />
      <motion.section
        variants={container}
        initial="hidden"
        animate="visible"
        className="container"
      >
        <motion.div className="content" variants={item} transition="easeInOut">
          <h1 className="text-jumbo lust-italic text-primary mt-16 mb-4 pb-10 font-normal">
            <i className="first-h">P</i>ix
            <i className="ss01">
              el<i className="hist">s</i>
            </i>{" "}
            Pushe<i className="hist">d</i>
          </h1>
        </motion.div>

        <motion.div className="content" variants={item} transition="easeInOut">
          {/* <p className="text-lg md:text-xl pl-3 border-l-2 border-accent">Work</p> */}
          <h3 className="text-lg md:text-xl pb-4 border-b border-accent">
            Apps &amp; Companies
          </h3>
        </motion.div>

        <motion.div className="content" variants={item} transition="easeInOut">
          <p>
            I have been the principal Product Designer at high-growth
            venture-backed startups for the past 15 years or so. My experience
            encompasses shaping the visual design, UI, and data visualization of
            a B2B SaaS app. I'm a sucker for the well executed basics of a clean
            layout, vibrant color palette, and ample white space around the
            typography.
          </p>
        </motion.div>
      </motion.section>

      <motion.section
        className="mx-3 my-16 md:mx-16 opacity-0"
        animate={{ x: 0, y: -10, opacity: 1 }}
        transition={{
          delay: 1,
          x: { type: "spring", stiffness: 100 },
          default: { duration: 0.5 },
        }}
      >
        <motion.div
          className="lg:w-4/5 w-full lg:flex m-auto border-l border-secondary pb-20"
          variants={item}
          transition="easeInOut"
        >
          <motion.div
            className="lg:w-2/5 px-8 w-full"
            variants={item}
            transition="easeInOut"
          >
            <time className="dot pink border-secondary"></time>
            <p>
              <small>
                Spring '21 - <span className="text-accent">present</span>
              </small>
            </p>
            <h4></h4>

            <p>
              <small>Company</small>
            </p>
            <h4>Reprise</h4>

            <p>
              <small>Type</small>
            </p>
            <h4>B2B, Enterprise SaaS</h4>

            <p>
              <small>Responsibilities</small>
            </p>
            <h4>Design Leadership, Product Design, Brand</h4>
          </motion.div>
          <motion.div
            className="lg:w-3/5 w-full"
            variants={item}
            transition="easeInOut"
          >
            <img
              src={work_reprise}
              className=" w-full mb-20 rounded-lg shadow-xl"
            ></img>
          </motion.div>
        </motion.div>
        
        <motion.div
          className="lg:w-4/5 w-full lg:flex m-auto border-l border-secondary pb-20"
          variants={item}
          transition="easeInOut"
        >
          <motion.div
            className="lg:w-2/5 px-8 w-full"
            variants={item}
            transition="easeInOut"
          >
            <time className="dot border-secondary"></time>
            <p>
              <small>
                Fall '17 - Spring '21, 3y+
              </small>
            </p>
            <h4></h4>

            <p>
              <small>Company</small>
            </p>
            <h4>Catalant</h4>

            <p>
              <small>Type</small>
            </p>
            <h4>Marketplace, Enterprise SaaS</h4>

            <p>
              <small>Responsibilities</small>
            </p>
            <h4>Design Leadership, Product Design, Brand</h4>
          </motion.div>
          <motion.div
            className="lg:w-3/5 w-full"
            variants={item}
            transition="easeInOut"
          >
            <img
              src={work_catalant}
              className=" w-full mb-20 rounded-lg shadow-xl"
            ></img>
          </motion.div>
        </motion.div>

        <motion.div
          className="lg:w-4/5 lg:flex m-auto border-l border-secondary pb-20"
          variants={item}
          transition="easeInOut"
        >
          <motion.div
            className="lg:w-2/5 px-8 w-full"
            variants={item}
            transition="easeInOut"
          >
            <time className="dot border-secondary"></time>
            <p>
              <small>Spring '11 - Fall '17, 6y+</small>
            </p>
            <h4></h4>

            <p>
              <small>Company</small>
            </p>
            <h4>InsightSquared</h4>

            <p>
              <small>Types</small>
            </p>
            <h4>SMB SaaS</h4>

            <p>
              <small>Responsibilities</small>
            </p>
            <h4>Product Design, Brand, Design Leadership</h4>
          </motion.div>
          <motion.div
            className="lg:w-3/5 w-full"
            variants={item}
            transition="easeInOut"
          >
            <img
              src={work_insightsquared}
              className=" w-full mb-20 rounded-lg shadow-xl"
            ></img>
          </motion.div>
        </motion.div>

        <motion.div
          className="lg:w-4/5 lg:flex m-auto border-l border-secondary pb-20"
          variants={item}
          transition="easeInOut"
        >
          <motion.div
            className="lg:w-2/5 w-full px-8"
            variants={item}
            transition="easeInOut"
          >
            <time className="dot"></time>
            <p>
              <small>Spring '09 - Spring '11, 2y</small>
            </p>
            <h4></h4>

            <p>
              <small>Company</small>
            </p>
            <h4>LevelUp</h4>

            <p>
              <small>Type</small>
            </p>
            <h4>B2C, Mobile</h4>

            <p>
              <small>Domains</small>
            </p>
            <h4>Product Design, Brand, Design Leadership</h4>
          </motion.div>
          <motion.div
            className="lg:w-3/5 w-full "
            variants={item}
            transition="easeInOut"
          >
            <img
              src={work_levelup}
              className=" w-full mb-20 rounded-lg shadow-xl"
            ></img>
          </motion.div>
        </motion.div>
      </motion.section>
    </>
  )
}

export default WorkPage
